import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

async function userDataGet() {
  // console.log("userDataGet");
  const res = await request("/api/auth/check");
  if (res.token) loStorage.set(res.token);
  return res;
}

// compare given roles array with current login roles
// async function rolesCheck(allowedRoles = null) {
//   // if empty array
//   if (!allowedRoles) return false;
//   const res = await request("/api/auth/check");
//   // console.log(res);
//   if (res.token && res.roles && res.uid) {
//     // through both arrays
//     const arr = allowedRoles.map((x) => res.roles.includes(x));
//     return arr.includes(true);
//   }
//   return false;
// }

// return roles array
// async function rolesGet() {
//   const res = await request("/api/auth/check");
//   if (res.token && res.roles) {
//     loStorage.set(res.token);
//     return res.roles;
//   }
//   return null;
// }

// request to server with Bearer header got from local storage
async function request(url, method = "GET", data = null, routePath, fileUpload) {
  try {
    const headers = {};
    let body;

    // authorization
    if (localStorage.getItem("token") && localStorage.getItem("token") != "undefined") {
      headers["Authorization"] = "Bearer" + " " + localStorage.getItem("token");
    }

    // if true, file uplod action process, use formData object with all data
    if (fileUpload) {
      // console.log("fileUpload", fileUpload);
      body = data;
    }

    if (!fileUpload && data) {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(data);
    }

    // if route path set, store request payload in localstorage
    if (routePath) {
      if (data) {
        loStorage.saveObject(routePath, data);
      }
    }

    // console.log('request begin')
    // console.log(url)
    // console.log(method)
    // console.log(headers)

    const response = await fetch(url, {
      method,
      headers,
      body,
    });
    return await response.json();
    //    return await response.text();
  } catch (e) {
    console.warn("Error:", e.message);
    return null;
  }
}

export { request /*, rolesGet, rolesCheck,*/, userDataGet };
